import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const Services = () => {
  const { t } = useTranslation("services")

  return (
    <>
      <div className="w-screen bg-black flex items-center justify-center min-h-screen lg:-mt-32 lg:-mb-32 py-8   ">
        <div className="flex flex-col  xl:flex-row items-center xl:items-start lg:justify-center">
          <div className="flex flex-col items-center text-center max-w-md my-10  text-white px-4 h-full">
            <div className="flex items-center justify-center h-20 w-20 p-1 rounded-full bg-orange-construction">
              <StaticImage
                height={4064}
                src="../../../images/obrero.png"
                alt="constructor icon"
                placeholder="tracedSVG"
              />
            </div>
            <h2 className="font-montserrat-semi text-base xs:whitespace-nowrap lg:text-xl py-4  text-center">
              {t("service1")}
            </h2>
            <p className="font-montserrat-reg text-center text-sm">
              {t("desc1")}
            </p>
          </div>
          <div className="flex flex-col items-center text-center max-w-md my-10  text-white px-4 h-full">
            <div className="flex items-center justify-center h-20 w-20 p-1 rounded-full bg-orange-construction">
              <StaticImage
                height={4064}
                src="../../../images/obrero.png"
                alt="constructor icon"
                placeholder="tracedSVG"
              />
            </div>
            <h2 className="font-montserrat-semi text-base xs:whitespace-nowrap lg:text-xl py-4  text-center">
              {t("service2")}
            </h2>
            <p className="font-montserrat-reg text-center text-sm">
              {t("desc2")}
            </p>
          </div>
          <div className="flex flex-col items-center text-center max-w-md my-10  text-white px-4 h-full">
            <div className="flex items-center justify-center h-20 w-20 p-1 rounded-full bg-orange-construction">
              <StaticImage
                height={4064}
                src="../../../images/obrero.png"
                alt="constructor icon"
                placeholder="tracedSVG"
              />
            </div>
            <h2 className="font-montserrat-semi text-base xs:whitespace-nowrap lg:text-xl py-4  text-center">
              {t("service3")}
            </h2>
            <p className="font-montserrat-reg text-center text-sm">
              {t("desc3")}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Services
